<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 73 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.7933 2.49859C22.525 1.51458 21.7373 0.741297 20.7351 0.477892C18.9203 1.43051e-07 11.64 0 11.64 0C11.64 0 4.35973 1.43051e-07 2.54493 0.477892C1.54268 0.741297 0.755049 1.51458 0.486757 2.49859C1.45705e-07 4.28033 0 7.99998 0 7.99998C0 7.99998 1.45705e-07 11.7197 0.486757 13.5014C0.755049 14.4854 1.54268 15.2587 2.54493 15.522C4.35973 16 11.64 16 11.64 16C11.64 16 18.9203 16 20.7351 15.522C21.7373 15.2587 22.525 14.4854 22.7933 13.5014C23.28 11.7197 23.28 7.99998 23.28 7.99998C23.28 7.99998 23.2781 4.28033 22.7933 2.49859Z"
      fill="#858585"
    />
    <path d="M9.31006 11.4283L15.3581 8.00031L9.31006 4.57227V11.4283Z" fill="white" />
    <path
      d="M28.1957 10.4019L25.5818 1.13379H27.8623L28.7783 5.33509C29.0121 6.3699 29.1826 7.2523 29.2938 7.98231H29.3608C29.4375 7.45926 29.61 6.58251 29.8764 5.35015L30.825 1.13379H33.1054L30.4589 10.4019V14.8478H28.1938V10.4019H28.1957Z"
      fill="#858585"
    />
    <path
      d="M33.7914 14.5547C33.3315 14.2499 33.0038 13.7758 32.8083 13.1323C32.6148 12.4889 32.5171 11.6347 32.5171 10.566V9.11164C32.5171 8.03356 32.6282 7.16624 32.8505 6.51337C33.0728 5.86051 33.4197 5.38261 33.8911 5.08346C34.3625 4.78431 34.9815 4.63379 35.7481 4.63379C36.5031 4.63379 37.1068 4.78619 37.5628 5.09099C38.017 5.39578 38.3504 5.87367 38.5612 6.5209C38.772 7.17 38.8775 8.03356 38.8775 9.11164V10.566C38.8775 11.6347 38.774 12.4927 38.569 13.1399C38.3639 13.789 38.0305 14.2631 37.5705 14.5623C37.1106 14.8615 36.4858 15.0119 35.6982 15.0119C34.8857 15.0138 34.2514 14.8595 33.7914 14.5547ZM36.3709 12.9856C36.4973 12.6583 36.5625 12.1258 36.5625 11.3845V8.26316C36.5625 7.54441 36.4993 7.0176 36.3709 6.68646C36.2425 6.35345 36.0183 6.18788 35.6963 6.18788C35.3859 6.18788 35.1655 6.35345 35.039 6.68646C34.9106 7.01949 34.8474 7.54441 34.8474 8.26316V11.3845C34.8474 12.1258 34.9086 12.6601 35.0314 12.9856C35.154 13.313 35.3743 13.4767 35.6963 13.4767C36.0183 13.4767 36.2425 13.313 36.3709 12.9856Z"
      fill="#858585"
    />
    <path
      d="M46.2958 14.8497H44.4983L44.299 13.623H44.2492C43.7605 14.5487 43.0284 15.0115 42.0511 15.0115C41.3746 15.0115 40.8745 14.7932 40.5525 14.3587C40.2306 13.9221 40.0696 13.2411 40.0696 12.3154V4.82903H42.3673V12.1836C42.3673 12.6315 42.4171 12.9494 42.5168 13.1395C42.6164 13.3295 42.7832 13.4254 43.0169 13.4254C43.2162 13.4254 43.4079 13.3652 43.5919 13.2448C43.7759 13.1244 43.91 12.972 44 12.7876V4.82715H46.2958V14.8497Z"
      fill="#858585"
    />
    <path d="M52.5374 2.94947H50.2569V14.8497H48.009V2.94947H45.7285V1.13574H52.5374V2.94947Z" fill="#858585" />
    <path
      d="M58.0795 14.8497H56.282L56.0827 13.623H56.0329C55.5442 14.5487 54.8122 15.0115 53.8348 15.0115C53.1583 15.0115 52.6582 14.7932 52.3362 14.3587C52.0143 13.9221 51.8533 13.2411 51.8533 12.3154V4.82903H54.151V12.1836C54.151 12.6315 54.2008 12.9494 54.3005 13.1395C54.4001 13.3295 54.5668 13.4254 54.8007 13.4254C54.9999 13.4254 55.1916 13.3652 55.3756 13.2448C55.5596 13.1244 55.6937 12.972 55.7837 12.7876V4.82715H58.0795V14.8497Z"
      fill="#858585"
    />
    <path
      d="M65.6837 6.43036C65.5438 5.79819 65.3195 5.341 65.0092 5.05689C64.6987 4.7728 64.2713 4.63169 63.7271 4.63169C63.3055 4.63169 62.9107 4.74833 62.5447 4.98352C62.1787 5.2187 61.895 5.52538 61.6957 5.90731H61.6785V0.62793H59.4651V14.848H61.3623L61.5961 13.8998H61.6459C61.8241 14.2384 62.0905 14.5037 62.445 14.7013C62.7996 14.897 63.1944 14.9948 63.6275 14.9948C64.4036 14.9948 64.9766 14.643 65.3426 13.9411C65.7086 13.2375 65.8925 12.1406 65.8925 10.6468V9.06068C65.8925 7.94117 65.8216 7.06254 65.6837 6.43036ZM63.5776 10.5188C63.5776 11.2488 63.5469 11.8207 63.4856 12.2347C63.4243 12.6486 63.3227 12.944 63.1771 13.1171C63.0333 13.292 62.8379 13.3786 62.5945 13.3786C62.4048 13.3786 62.2304 13.3353 62.0694 13.2469C61.9085 13.1603 61.7782 13.0287 61.6785 12.8555V7.1679C61.7551 6.89508 61.8893 6.67307 62.079 6.4981C62.2668 6.32312 62.4738 6.23657 62.6941 6.23657C62.928 6.23657 63.1081 6.32688 63.2346 6.50563C63.363 6.68624 63.4511 6.98728 63.501 7.41249C63.5508 7.83769 63.5757 8.44164 63.5757 9.22619V10.5188H63.5776Z"
      fill="#858585"
    />
    <path
      d="M69.1522 11.0968C69.1522 11.7402 69.1713 12.2219 69.2097 12.5436C69.248 12.8653 69.3285 13.0987 69.4512 13.2473C69.5738 13.394 69.7616 13.4674 70.0165 13.4674C70.3595 13.4674 70.5971 13.3357 70.7236 13.0742C70.852 12.8127 70.921 12.3762 70.9325 11.7666L72.9141 11.8813C72.9256 11.9679 72.9313 12.0883 72.9313 12.2407C72.9313 13.1663 72.6725 13.8587 72.1571 14.3159C71.6416 14.7731 70.9114 15.0027 69.9686 15.0027C68.836 15.0027 68.0426 14.6546 67.5884 13.9566C67.1324 13.2586 66.9062 12.1805 66.9062 10.7204V8.97068C66.9062 7.46742 67.142 6.36864 67.6134 5.67627C68.0848 4.98389 68.8916 4.6377 70.0356 4.6377C70.8233 4.6377 71.4289 4.77881 71.8505 5.06291C72.2721 5.34701 72.5691 5.78727 72.7416 6.38746C72.9141 6.98764 73.0003 7.81549 73.0003 8.87284V10.5888H69.1522V11.0968ZM69.4435 6.37429C69.3266 6.5154 69.25 6.74682 69.2097 7.06855C69.1713 7.39028 69.1522 7.87758 69.1522 8.53236V9.25108H70.8329V8.53236C70.8329 7.88886 70.8099 7.40157 70.7658 7.06855C70.7217 6.73553 70.6412 6.50223 70.5243 6.36488C70.4075 6.22942 70.2273 6.1598 69.9839 6.1598C69.7386 6.16168 69.5585 6.23318 69.4435 6.37429Z"
      fill="#858585"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '80'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
